export enum TReduxTag {
  UserProfile = 'UserProfile',
  VideoQueries = 'VideoQueries',
  RidershipValidations = 'RidershipValidations',
  GeographicalQueries = 'GeographicalQueries',
  Jobs = 'Jobs',
}

export const reduxTags = [
  TReduxTag.UserProfile,
  TReduxTag.VideoQueries,
  TReduxTag.RidershipValidations,
  TReduxTag.GeographicalQueries,
  TReduxTag.Jobs,
];
