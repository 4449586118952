export namespace StorageUtils {
  type StorageType = 'localStorage' | 'sessionStorage';

  const getStorage = (storageType?: StorageType): Storage => {
    switch (storageType) {
      case 'localStorage':
        return localStorage;
      case 'sessionStorage':
        return sessionStorage;
      default:
        return localStorage;
    }
  };

  export function search<T>(key: string, storageType?: StorageType): T | null {
    // check if storage is available on client rendering
    if (typeof window === 'undefined') return null;

    try {
      // if storage type is not provided then search in both
      if (!storageType) {
        // search in local storage or session storage
        const item = localStorage.getItem(key) || sessionStorage.getItem(key);
        if (item) {
          return JSON.parse(item) as T;
        }
      } else {
        // search in the provided storage type
        const storage = getStorage(storageType);
        const item = storage.getItem(key);
        if (item) {
          return JSON.parse(item) as T;
        }
      }
    } catch (error) {
      console.error(`Error searching for key '${key}' in ${storageType ?? 'both storages'}:`, error);
    }

    return null;
  }

  export function set<T>(key: string, value: T, storageType: StorageType = 'localStorage'): void {
    // check if storage is available on client rendering
    if (typeof window === 'undefined') return;

    try {
      const storage = getStorage(storageType);
      storage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Error setting key '${key}' in ${storageType}:`, error);
    }
  }

  export function remove(key: string, storageType?: StorageType): void {
    // check if storage is available on client rendering
    if (typeof window === 'undefined') return;

    try {
      if (!storageType) {
        localStorage.removeItem(key);
        sessionStorage.removeItem(key);
      } else {
        const storage = getStorage(storageType);
        storage.removeItem(key);
      }
    } catch (error) {
      console.error(`Error removing key '${key}' from ${storageType ?? 'both storages'}:`, error);
    }
  }

  export function clear(storageType?: StorageType): void {
    // check if storage is available on client rendering
    if (typeof window === 'undefined') return;

    switch (storageType) {
      case 'localStorage':
        localStorage.clear();
        break;
      case 'sessionStorage':
        sessionStorage.clear();
        break;
      default:
        localStorage.clear();
        sessionStorage.clear();
    }
  }
}
