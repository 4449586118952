import { JSX } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import styles from './index.module.scss';

interface IProps {
  size?: number;
  fullPage?: boolean;
}

export default function Loader({
  size = 48,
  fullPage,
}: IProps): JSX.Element {
  return (
    <>
      {fullPage
        ? (
          <Box className={styles.loaderWrapper}>
            <CircularProgress size={size} thickness={5} />
          </Box>
        )
        : (
          <CircularProgress size={size} thickness={5} />
        )}
    </>
  );
}
