import { TAuthFeature } from '@th-common/enums/auth-feature.enum';
import { TAuthMethod } from '@th-common/enums/auth-method.enum';

import { RootState } from '../store';

export const selectors = {
  isUserAuthenticated: (state: RootState): boolean => {
    const token = state.auth.token;
    const refreshToken = state.auth.refreshToken;
    const expire = state.auth.expiresIn ?? null;
    const now = Math.round(Date.now() / 1000);

    return !!(token && refreshToken && expire && now < expire);
  },

  authToken: (state: RootState): string | null => state.auth.token,
  authMethod: (state: RootState): TAuthMethod => state.auth.authenticationMethod,
  authRefreshToken: (state: RootState): string | null => state.auth.refreshToken,
  authTokenExpires: (state: RootState): number | null => state.auth.expiresIn,
  isRemember: (state: RootState): boolean => state.auth.isRemember,
  features: (state: RootState): TAuthFeature[] => state.auth.features,
};
