/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { INotification, TNotificationType } from './index.interface';

export interface INotificationState {
  activeNotification: INotification | null;
}

const initialState: INotificationState = {
  activeNotification: null,
};

export const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    reset: () => initialState,
    showNotification: (state, action: { payload: INotification }) => {
      if (action.payload.message) {
        state.activeNotification = {
          ...action.payload,
          duration: action.payload.duration || 3000,
        };
      }
    },
    showErrorNotification: (state, action) => {
      if (action.payload.message) {
        state.activeNotification = {
          ...action.payload,
          type: TNotificationType.ERROR,
          duration: action.payload.duration || 3000,
        };
      }
    },
    clearNotification: (state) => {
      state.activeNotification = null;
    },
  },
  extraReducers: () => {},
});

export const { showErrorNotification } = slice.actions;
export default slice.reducer;
