'use client';

import { JSX } from 'react';
import { authGuard } from '@th-common/guards/auth.guard';
import { config } from '@th-common/utils/config';
import { redirect } from 'next/navigation';

function HomePage(): JSX.Element {
  redirect(config.routes.homePageUrl);
}

export default authGuard(HomePage);

export const dynamic = 'force-static';
