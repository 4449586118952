import { BaseQueryApi, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { THttpStatusCode } from '@th-common/enums/http/http-status-code';
import { THttpErrorResponse } from '@th-common/interfaces/http-error';

import { showErrorNotification } from './notification/slice';

export interface IBaseQueryExtraOptions {
  skipErrorNotification?: THttpStatusCode[] | boolean;
  errorStatusToMessage?: { [key in THttpStatusCode]?: string };
}

export const errorDetailMessageByStatus = (error: FetchBaseQueryError, extraOptions: IBaseQueryExtraOptions): string | null => {
  const skipErrorNotification = extraOptions && (Array.isArray(extraOptions.skipErrorNotification)
    ? extraOptions.skipErrorNotification.includes(error.status as THttpStatusCode)
      : extraOptions.skipErrorNotification);

  if (skipErrorNotification) {
    return null;
  }

  const errorData = error.data as THttpErrorResponse;

  // Error status is THttpStatusCode enum value
  if (typeof error.status === 'number') {
    if (error.status === THttpStatusCode.Unauthorized) {
      return null;
    }

    const message = extraOptions && extraOptions.errorStatusToMessage && extraOptions.errorStatusToMessage[error.status as THttpStatusCode];
    if (message) {
      return message;
    }

    if (error.status === THttpStatusCode.NotFound
      || error.status >= THttpStatusCode.InternalServerError
    ) {
      return 'An unexpected error has occurred.';
    }
  }

  if (typeof error.status === 'string') {
    if (error.status === 'FETCH_ERROR') {
      // Browser is offline
      if (!navigator.onLine) {
        return 'No internet connection.';
      }
      return 'An unexpected error has occurred.';
    }
  }

  return errorData.detail;
};

export const handleErrorNotification = (
  api: BaseQueryApi,
  error: FetchBaseQueryError,
  extraOptions: IBaseQueryExtraOptions,
): THttpErrorResponse | null => {
  const errorDetailMessage = errorDetailMessageByStatus(error, extraOptions);

  if (errorDetailMessage) {
    api.dispatch(showErrorNotification({
      message: errorDetailMessage,
    }));

    return {
      ...(error.data || {}),
      detail: errorDetailMessage,
    } as THttpErrorResponse;
  }

  return null;
};
