import { THttpStatusCode } from '@th-common/enums/http/http-status-code';
import { IAuthResponse, ICredentials } from '@th-common/interfaces/auth';
import { apiRoot } from '@th-common/store/api';

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    authenticate: builder.mutation<IAuthResponse, ICredentials>({
      query: (credentials: ICredentials) => ({
        url: 'token',
        method: 'POST',
        body: credentials,
      }),
      extraOptions: {
        skipErrorNotification: [THttpStatusCode.Forbidden],
      },
    }),

    refreshAuthToken: builder.mutation<IAuthResponse, string | null>({
      query: (refreshToken) => `token/renew?refreshToken=${refreshToken}`,
    }),

    windowsAuthenticate: builder.mutation<IAuthResponse, void>({
      query: () => ({
        url: 'token/activedirectory',
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: {},
      }),
      extraOptions: {
        errorStatusToMessage: {
          [THttpStatusCode.NotFound]: 'Windows authentication is not available.',
        },
      },
    }),
  }),
});
