/* eslint-disable no-param-reassign */
import type { Dayjs, PluginFunc } from 'dayjs';
import dayjs from 'dayjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
const REGEX_TIMEZONE_OFFSET_FORMAT = /^(.*)([+-])(\d{2}):(\d{2})|(Z)$/;

/**
 * Plugin base on the following issue:
 * @see https://github.com/iamkun/dayjs/issues/651#issuecomment-763033265
 * decorates dayjs in order to keep the utcOffset of the given date string
 * natively dayjs auto-converts to local time & losing utcOffset info.
 */
const pluginFunc: PluginFunc<unknown> = (
  option: unknown,
  dayjsClass: typeof Dayjs,
  dayjsFactory: typeof dayjs,
) => {
  dayjsFactory.parseZone = (
    date?: dayjs.ConfigType,
    format?: dayjs.OptionType,
    locale?: string,
    strict?: boolean,
  ) => {
    if (!date) {
      return dayjs();
    }
    if (typeof format === 'string') {
      format = { format };
    }

    if (typeof date !== 'string') {
      return dayjs(date, format, locale, strict);
    }
    const match = date.match(REGEX_TIMEZONE_OFFSET_FORMAT);
    if (match === null || match[0] === 'Z') {
      return dayjs(date);
    }

    // if (match[0] === 'Z') {
    //   return dayjs(
    //     date,
    //     {
    //       utc: true,
    //       ...format,
    //     },
    //     locale,
    //     strict,
    //   );
    // }

    const [, dateTime] = match;
    return dayjs(
      dateTime,
      format,
      locale,
      strict,
    );
  };
};

export default pluginFunc;

declare module 'dayjs' {
  function parseZone(
    date?: dayjs.ConfigType,
    format?: dayjs.OptionType,
    locale?: string,
    strict?: boolean
  ): dayjs.Dayjs;
}
