export enum TNotificationType {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
}

export interface INotification {
  type: TNotificationType;
  message: string;
  duration?: number;
}
