/* eslint-disable @typescript-eslint/naming-convention */
export class StorageAuthKeys {
  static readonly TOKEN = 'auth-token';

  static readonly REFRESH_TOKEN = 'auth-refresh-token';

  static readonly TOKEN_EXPIRES = 'auth-expiry';

  static readonly AUTH_METHOD = 'auth-method';

  static readonly IS_REMEMBER = 'is-remember';

  static readonly FEATURES = 'features';

  static readonly USER_GROUPS_IDS = 'user-groups-ids';
}
