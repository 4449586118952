import { api } from './api';
import { selectors } from './selectors';
import { slice } from './slice';

interface IAuthStore {
  actions: typeof slice.actions;
  selectors: typeof selectors;
  api: typeof api;
}

const authStore: IAuthStore = {
  actions: slice.actions,
  selectors,
  api,
};

export { authStore };
